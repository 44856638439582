import React from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import '../styles/contactform.css'
import { navigate } from 'gatsby';

const ContactForm = () => {
    const handleSubmit = async (event) => {
        event.preventDefault();
      
        // extract form data
        const formdata = new FormData(event.target)
      
        // convert FormData to json object
        // SOURCE: https://stackoverflow.com/a/46774073
        const json = {}
        formdata.forEach(function(value, prop){
          json[prop] = value
        })
      
        // convert json to urlencoded query string
        // SOURCE: https://stackoverflow.com/a/37562814 (comments)
        const formBody = Object.keys(json).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(json[key])).join('&')
      
        // POST the request to API endpoint
        const response = await fetch("https://forms.hubspot.com/uploads/form/v2/6796072/ba0954c0-37d1-4d07-a073-a5d9688d61d3", {
          method: "POST",
          mode: "no-cors",
          headers: {"Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin":"*"},
          body: formBody,
        }).then(response => {
            // identify visitor
            const _hsq = window._hsq = window._hsq || [];
                _hsq.push(["identify",{
                    email: formBody.email,
                }]);
            // reset form
            document.getElementById("contactUsForm").reset()
            navigate('/thank-you')
            // display success message
            // document.getElementById("success").style.display = "block"
            // document.getElementById("modalForm").style.display="none"
            // document.getElementById("headerMsg").style.display="none"
          })
          .catch(error => {
            console.log(error)
            // document.getElementById("failure").style.display = "block"
            // document.getElementById("modalForm").style.display = "none"
            // document.getElementById("headerMsg").style.display="none"
          })
      }

    return(
        <div className="contact-form">
            <Container className="py-5">
                <Row className="justify-content-center">
                    <span className="gradient-text blue">
                        Contact Us
                    </span>
                </Row>
                <Row className="justify-content-center">
                    <h2 className="svc-h2 mt-3 mb-3 text-center">
                        Reach Us in Just a Couple of Clicks
                    </h2>
                </Row>
                <Row className="justify-content-center">
                    <Col className="col-8">
                        <p className="svc-text text-center">
                            Have a question about how Codev Media can help you? Or maybe you just want to chat about the latest Groovefunnels or API integrations. We’re here for either. Contact us now!
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Form className="contactForm" method="POST" onSubmit={handleSubmit} id="contactUsForm">
                        <Form.Row>
                            <Form.Group as={Col} controlId="formFullName">
                                <Form.Control aria-label="Name" name="name" type="text" placeholder="Name..." className="formInput" required/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="formEmail">
                                <Form.Control aria-label="Email" name="email" type="email" placeholder="Email..." className="formInput" required/>
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="formSubject">
                            <Form.Control aria-label="Subject" name="subject" type="text" placeholder="Subject..." className="formInput" required/>
                        </Form.Group>

                        <Form.Group controlId="FormMessage">
                            <Form.Control aria-label="Message" name="message" rows={5} as="textarea" placeholder="Message..." className="formInput" required/>
                        </Form.Group>
                        <Form.Row className="justify-content-center">
                        <Button className="more-btn btn" type="submit" aria-label='Form Submission Button'>
                            Send Message
                        </Button>
                        </Form.Row>
                    </Form>
                </Row>
            </Container>
        </div>
    );
};

export default ContactForm;