import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import HeroInner from "../components/heroinner"
import ContactForm from "../components/contactform"

const Contact = () => (
  <Layout>
    <SEO title="Contact Us" description="Contact our website design and seo agency oklahoma city"/>
    <HeroInner title="Contact Us" />
    <ContactForm />
  </Layout>
)

export default Contact;